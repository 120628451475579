<template>
    <div class="current-user">
        <h2>{{ $t('template.account.heading') }}</h2>
        <div class="current-user__list">
            <div class="current-user__item">
                <div class="current-user__item-info">
                    <span class="current-user__item-icon">account_circle</span>
                    <span class="current-user__item-name">{{ user.email }}</span>
                </div>
                <div class="current-user__item-link">
                    <Button type="text" @click="changeAccount()">
                        {{ $t('template.account.buttons.changeAccount') }}
                    </Button>
                    <Button type="icon" icon="logout" @click="logout" class="logout-icon"></Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/common/Button';
import { reset } from '@/api/auth.ts';
import { accountUrl } from '@/helpers/values';

export default {
    name: 'CurrentUser',
    props: {
        user: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        Button,
    },
    data() {
        return {};
    },
    methods: {
        changeAccount() {
            location.href = `${accountUrl}/login`;
        },
        async logout() {
            await reset();
            console.log('logout redirect');
            location.replace(`${accountUrl}/auth/logout`);
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.current-user {
    &__list {
        border: 1px solid #f5f7fa;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f5f7fa;
        min-height: 64px;

        &:last-child {
            border-bottom: none;
        }
        &-info {
            position: relative;
            padding-left: 54px;
            display: flex;
            align-items: center;
        }
        &-link {
            padding: 0 10px 0 0;
        }

        &-icon {
            position: absolute;
            left: 14px;
            top: 50%;
            margin-top: -14px;
            font-family: 'Material Icons Outlined';
            font-size: 28px;
            width: 28px;
            height: 28px;
            color: $primary;
            opacity: 0.6;
        }
        &-name {
            display: block;
            font-size: 16px;
            line-height: 24px;
            color: #2c2d2e;
        }
    }

    &__btn-add {
        margin-top: 32px;
        width: 100%;
        height: 42px;
        justify-content: center;
        span {
            flex: 0;
        }
    }
    .logout-icon {
        margin-left: 8px;
        .btn__icon {
            font-size: 20px;
        }
    }
}
</style>
